import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css'; // Add this line

function NotFoundPage() {
  return (
    <div className="not-found-page">
      <h1>Error!</h1>
      <p>Error 404: Page not found</p>
      <Link to="/">Go back to the home page</Link>
    </div>
  );
}

export default NotFoundPage;