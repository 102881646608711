import React from 'react';
import { Link } from 'react-router-dom';
import './NothingHere.css'; // Add this line

function NothingHere() {
  return (
    <div className="nothing-here">
      <h1>Welcome to my Chat</h1>
      <p>You should specify the chatroom name/number like this:</p>
      <Link to="/chatroom/123456">/chatroom/123456</Link>
    </div>
  );
}

export default NothingHere;