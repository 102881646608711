import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import ChatPage from './Chat';
import NotFoundPage from './NotFoundPage'; // Import a NotFoundPage component
import NothingHere from './NothingHere';

function ChatRouter() {
  const { roomname } = useParams();
  // const username = new URLSearchParams(window.location.search).get('username');
  // if (roomname && username) {
  if (roomname) {
    return <ChatPage roomname={roomname} />;
  }
  return <NotFoundPage />;
}

export function App({ signOut, user }) {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<NothingHere />} />
        <Route path="/chatroom/:roomname" element={<ChatRouter />} />
        <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 */}
      </Routes>
    </Router>
  );
}

export default App;
