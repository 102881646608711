import React, { useEffect, useState, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import { useParams } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import config from './aws-exports';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabulariesForLanguage('es', {
  Username: "Correo Electrónico",
  "Please confirm your Password": "Confirme su contraseña",
  "Enter your Username": "Escriba su correo"
});
Amplify.configure(config);

function ChatPage({ signOut, user }) {
  const [tokens, setTokens] = useState({accessToken: '', idToken: ''});
  // Estado para almacenar el valor actual del input
  const [inputValue, setInputValue] = useState('');
  // Estado para almacenar todos los mensajes enviados
  const [messages, setMessages] = useState([]);

  const [currentWebSocket, setCurrentWebSocket] = useState(null);

  const { roomname } = useParams();
  const username = user?.signInDetails?.loginId;
  const wss = document.location.protocol === "http:" ? "ws://" : "wss://";

  const chatLogRef = useRef(null);
  const rosterRef = useRef(null);

  // Función para manejar el cambio de texto en el input
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  async function currentSession() {
    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken?.toString() ?? '';
      const idToken = session.tokens?.idToken?.toString() ?? '';
      return { accessToken, idToken };
    } catch (err) {
      console.error("Error fetching session: ", err);
      return { accessToken: '', idToken: '' };  // Garantiza devolver siempre strings
    }
  }

  function addMessageToChatlog(msgUsr, message) {
    const newMessageDiv = document.createElement('div'); // Crea un nuevo div para el mensaje
    // newMessageDiv.textContent = message; // Asigna el texto del input al nuevo div

    if(msgUsr !== null){
      const newMsgUsr = document.createElement('strong');
      newMsgUsr.textContent = username === msgUsr? "(yo): ": msgUsr + ": ";
      newMessageDiv.appendChild(newMsgUsr);
    }
    newMessageDiv.appendChild(document.createTextNode(message));

    chatLogRef.current.appendChild(newMessageDiv); // Añade el nuevo div al contenedor de chat log
    chatLogRef.current.scrollBy(0, 1e8);
  }
  
  // Función para manejar el envío de mensaje al presionar 'Enter'
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) { // Solo procede si 'Enter' es presionado y el input no está vacío
      setMessages([...messages, inputValue]); // Agrega el mensaje actual al array de mensajes
      if (currentWebSocket) {
        currentWebSocket.send(JSON.stringify(
          {
            "event_type": "message",  
            username: username,
            message: inputValue
          }));
        setInputValue(''); // Limpia el input después de enviar
      } else {
        console.log("WebSocket is not connected.");
      }
    }
  };

  function join(itoken) {
    if(itoken == undefined) itoken = tokens.idToken;
    console.log("Connecting user: ", user?.signInDetails?.loginId);
    if(itoken === ""){
      console.log("Error, no access token in session.", tokens);
      return;
    }
    let hostname = process.env.NODE_ENV === 'development' ? "localhost:8787" : process.env.REACT_APP_WSHOST;
    let ws = new WebSocket(wss + hostname + "/websocket/" + roomname + "?authentication=" + itoken);
    

    let rejoined = false;
    let startTime = Date.now();

    let rejoin = async () => {
      if (!rejoined) {
        rejoined = true;
        setCurrentWebSocket(null);
        // Don't try to reconnect too rapidly.
        let timeSinceLastJoin = Date.now() - startTime;
        if (timeSinceLastJoin < 10000) {
          // Less than 10 seconds elapsed since last join. Pause a bit.
          await new Promise(resolve => setTimeout(resolve, 10000 - timeSinceLastJoin));
        }
        // OK, reconnect now!
        join();
      }
    }

    // OPEN Event
    ws.addEventListener("open", event => {
      setCurrentWebSocket(ws);

      // Send user info message.
      ws.send(JSON.stringify({
        username: username,
        "event_type": "open",
      }));
      chatLogRef.current.innerHTML = "";
      addMessageToChatlog(null, "* Welcome to #" + roomname + ". Say hi!");
    });

    // MESSAGE Event
    ws.addEventListener("message", event => {
      let data = JSON.parse(event.data);

      if (data.error !== undefined) {
        addMessageToChatlog(null, "* Error: " + data.error);
      } else if (data.joined !== undefined || data.left !== undefined) {
        if (data.online !== undefined || data.quit !== undefined) {
          const roster = rosterRef.current;
          roster.innerHTML = "";
          data.online.forEach(function (onlineUsername, index) {
            let p = document.createElement("p");
            p.innerText = (username === onlineUsername ? "(me) " : "") + onlineUsername;
            roster.appendChild(p);
          });
          if (data.joined) {
            addMessageToChatlog(null, "* " + data.joined + " joined.");
          } else {
            addMessageToChatlog(null, "* " + data.left + " left.");
          }
        }
      } else if (data.history !== undefined) {
        addMessageToChatlog(data.username, data.message);
      } else {
        addMessageToChatlog(data.username, data.message);
      }
    });

    ws.addEventListener("close", event => {
      console.log("WebSocket closed, reconnecting:", event.code, event.reason);
      rejoin();
    });
    ws.addEventListener("error", event => {
        console.log("WebSocket error, reconnecting:", event);
        rejoin();
    });
  }

  function handleLeaveChat(event) {
    event.preventDefault();
    if (currentWebSocket) {
      currentWebSocket.close(1000);
    }
    // Got to home page.
    window.location.href = "/";
  }

  useEffect(() => {
    async function loadSession() {
      const { accessToken, idToken } = await currentSession();
      setTokens({accessToken, idToken });
      join(idToken);
    }
    console.log("Loading session...");
    loadSession();
  }, []);

  return (
    <Authenticator>
      <div id="chatroom">
        <div id="chatlog" ref={chatLogRef}></div>
        <div id="roster" ref={rosterRef}></div>
        <input
          id="chat-input"
          type="text"
          autoComplete="off"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message and press Enter"
        />
        <div id="chat-actions">
          <button id="chat-close" onClick={(event) => handleLeaveChat(event)}>Leave chat!</button>
          <button id='chat-signout' onClick={signOut}>Sign out</button>
        </div>
      </div>
    </Authenticator>
  );
}

export default withAuthenticator(ChatPage);

